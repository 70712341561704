export function dictFilter(value, dict) {
  let result = (dict || []).filter(item => item.value == value);
  if (result && result.length) {
    return result[0].name;
  }
  return value;
}
export function dateTimeFilter(date, format = "yyyy-MM-dd") {
  function p0(v) {
    return String(v).padStart(2, 0);
  }
  if (typeof date === "string" || typeof date === "number") {
    date = new Date(date);
  } else if (!date) {
    date = new Date();
  }
  var groups = {
    yyyy: date.getFullYear(),
    MM: p0(date.getMonth() + 1),
    dd: p0(date.getDate()),
    d: date.getDate(),
    HH: p0(date.getHours()),
    mm: p0(date.getMinutes()),
    ss: p0(date.getSeconds())
  };
  var result = format;
  for (var item in groups) {
    var RE = new RegExp(item, "g");
    result = result.replace(RE, groups[item]);
  }
  return result;
}
