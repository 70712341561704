<template>
  <div
    class="CardHeader"
    :style="{ 'padding-top': pt + 'px', 'padding-bottom': pb + 'px' }"
  >
    <div class="left">
      <span class="header-icons"></span>
      <span>{{ title }}</span>
    </div>
    <div class="right">
      <slot name="btn"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardHeader",
  props: {
    title: {
      type: String,
      required: true
    },
    isbtn: {
      type: Boolean,
      default: true
    },
    pt: {
      type: [String, Number],
      default: 40
    },
    pb: {
      type: [String, Number],
      default: 15
    }
  },
  components: {},
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
.CardHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
    .header-icons {
      display: inline-block;
      width: 4px;
      height: 20px;
      margin-right: 8px;
      background: #3d5afe;
      border-radius: 2px;
    }
  }
  .right {
    margin-top: 4px;
    a {
      user-select: none;
      font-size: 14px;
      color: #3d5afe;
      line-height: 17px;
      text-decoration: none;
      cursor: pointer;
      i {
        margin-right: 2px;
      }
      &:hover {
        color: #3d5afe;
      }
    }
  }
}
</style>
